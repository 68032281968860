import React from 'react';

function CommissionPage(props) {
    return (
        <div className={"container"} >
            <p className={"text1"}>
            If you would like to commission a painting from me please contact me on oliver.f.watkins@gmail for a discussion
            </p>
        </div>
    );
}

export default CommissionPage;