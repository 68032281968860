import React from 'react';
// import img_rebirth from "../images/ac61.jpg";
// import img_summer_party from "../images/ac62.jpg";
// import img_dogs from "../images/dogs.jpg";
// import str_parad_2 from "../images/ac29.jpg";
// import windmill from "../images/ac53.jpg";
// import img_cat_dinner from "../images/ac60.jpg";
// import i7 from "../images/ac56.jpg";
// import i8 from "../images/ac57.jpg";
// import i9 from "../images/ac58.jpg";
// import i10 from "../images/ac59.jpg";
// import str_para_1 from "../images/ac33.jpg";
// import img_at_the_bar from "../images/ac63.jpg";

function ContactPage(props) {
    return (
        <div className={"container"} >
        <p className={"text1"}>
            Contact me by writing me an email at oliver.f.watkins@gmail.com
            </p>
        </div>
    );
}

export default ContactPage;