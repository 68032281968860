import React from 'react';

function PurchasePage() {
    return (
        <div className={"container"} >

        <p className={"text1"}>
                For all purchasing enquiries please send an email to oliver.f.watkins@gmail.com
            </p>
        </div>
    );
}

export default PurchasePage;